import Autocomplete from 'react-google-autocomplete'
import {useState} from 'react'
import styles from './HeroSearch.module.scss'

export default function LocationsHeroSearch({location, compact, onChange}) {
  const [selectedLocation, setSelectedLocation] = useState({
    formattedAddress: location?.formattedAddress,
    lat: location?.lat,
    lon: location?.lon
  })
  const onLocationSelect = (location) => {
    const { lat, lng } = location.geometry.location
    const formattedAddress = location.formatted_address

    setSelectedLocation({
      formattedAddress,
      lat: lat(),
      lon: lng(),
    })

    onChange({
      formattedAddress,
      lat: lat(),
      lon: lng()
    })
  }

  return (
    <Autocomplete
      apiKey={process.env.NEXT_PUBLIC_GOOGLE_PLACES_AUTOCOMPLETE_API_KEY}
      onPlaceSelected={onLocationSelect}
      options={{
        types: ['(regions)'],
        componentRestrictions: {
          country: ['gb']
        }
      }}
      className={`${styles.searchControl} ${compact ? 'compact' : ''}`}
      defaultValue={selectedLocation.formattedAddress}
    />
  )
}
