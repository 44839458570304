import AsyncSelect from 'react-select/async'
import jsonApiClient from '../../utils/jsonApiClient'
import theme from '../../config/selectTheme'

const fetchLevels = async (inputValue, subject) => {
  const jsonApi = jsonApiClient('level')
  let filter = {}
  if(subject) { filter.for_subject = subject }

  const { data: levels } = await jsonApi.findAll('level', {
    filter,
    sort: 'name',
    page: {
      size: 1000
    }
  })

  return levels.map(level => (
    {
      value: level?.slug,
      label: level?.name
    }
  ))
}

export default function LevelsHeroSearch({compact, onChange, level, subject}) {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided) => ({
      ...provided,
      padding: compact ? '12px 10px' : '20px',
    })
  }

  return (
    <AsyncSelect cacheOptions
                 defaultOptions
                 isClearable
                 isSearchable={false}
                 styles={customStyles}
                 theme={theme}
                 loadOptions={(value) => (fetchLevels(value, subject))}
                 placeholder="Select a level"
                 value={level}
                 onChange={onChange}
    />
  )
}
