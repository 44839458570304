import AsyncSelect from 'react-select/async';
import jsonApiClient from '../../utils/jsonApiClient'
import theme from '../../config/selectTheme'

const fetchSubjects = async (inputValue) => {
  const jsonApi = jsonApiClient('subject')
  let params = {
    sort: 'name',
    page: {
      size: 1000
    }
  }
  if(inputValue) { params.autocomplete = inputValue }

  const { data: subjects } = await jsonApi.findAll('subject', params)

  return subjects.map(subject => (
    {
      value: subject?.slug,
      label: subject?.name
    }
  ))
}

export default function SubjectsHeroSearch({compact, onChange, subject}) {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided) => ({
      ...provided,
      padding: compact ? '12px 10px' : '20px'
    })
  }

  return (
    <AsyncSelect cacheOptions
                 defaultOptions
                 isClearable
                 isSearchable={false}
                 styles={customStyles}
                 theme={theme}
                 loadOptions={fetchSubjects}
                 placeholder="Select a subject"
                 value={subject}
                 onChange={onChange}
    />
  )
}
