import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircle as faCircleSolid, faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import {faCircle as faCircleRegular} from '@fortawesome/free-regular-svg-icons';
import styles from './HeroSearch.module.scss';
import SubjectsHeroSearch from './subjects/HeroSearch'
import LevelsHeroSearch from './levels/HeroSearch'
import LocationsHeroSearch from './locations/HeroSearch'

export default function HeroSearch({subject, level, modality, location, compact, showModalityControl = true}) {
  const router = useRouter()

  const [selectedModality, setSelectedModality] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)

  const isPresent = (object) => {
    return object && Object.keys(object).length > 0
  }

  useEffect(() => {
    if(!subject) return

    if(isPresent(subject)) {
      setSelectedSubject({
        value: subject?.slug,
        label: subject?.name
      })
    } else {
      setSelectedSubject(null)
    }

    console.log(subject)
  }, [subject])

  useEffect(() => {
    if(!level) return

    if(isPresent(level)) {
      setSelectedLevel({
        value: level?.slug,
        label: level?.name
      })
    } else {
      setSelectedLevel(null)
    }
  }, [level])

  useEffect(() => {
    setSelectedModality(modality || 'online')
  }, [modality])

  useEffect(() => {
    setSelectedLocation({
      lat: location?.lat,
      lon: location?.lon,
      formattedAddress: location?.formattedAddress
    })
  }, [location])

  const onModalityChange = (value) => {
    if(value === 'online') setSelectedLocation({})
    setSelectedModality(value)
  }

  const controlMarginRight = (compact ? 'me-3' : 'me-4')
  const modalityControlClass = compact ? styles.compactModalityControl : styles.expandedModalityControl
  const searchControlGroupClass = compact ? styles.compactSearchControlGroup : ''

  const performSearch = () => {
    router.push({
      pathname: '/tutors/search',
      query: {
        subject: selectedSubject?.value,
        level: selectedLevel?.value,
        lat: selectedLocation?.lat,
        lon: selectedLocation?.lon,
        address: selectedLocation?.formattedAddress,
        modality: selectedModality
      }
    })
  }

  return (
    <div className={compact ? 'd-md-flex' : ''}>
      {showModalityControl && (
        <div className={`${modalityControlClass} d-flex align-items-center`}>
          <ToggleButtonGroup type="radio" name="modality" value={selectedModality} onChange={onModalityChange}>
            <ToggleButton variant="outline-primary" id="modality-in-online" value="online">
              <FontAwesomeIcon
                icon={selectedModality === 'online' ? faCircleSolid : faCircleRegular}
                className="me-2"
              />
              Online
            </ToggleButton>
            <ToggleButton variant="outline-primary" id="modality-in-person" value="in_person">
              <FontAwesomeIcon
                icon={selectedModality === 'in_person' ? faCircleSolid : faCircleRegular}
                className="me-2"
              />
              In Person
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}

      <div className={`${searchControlGroupClass} d-block d-md-flex justify-content-center`}>
        <div className={`${controlMarginRight} d-block d-md-flex w-100 mb-md-0 mb-2`}>
          <SubjectsHeroSearch subject={selectedSubject} onChange={setSelectedSubject} compact={compact} />
        </div>

        <div className={`${controlMarginRight} d-block d-md-flex w-100 mb-md-0 mb-2`}>
          <LevelsHeroSearch level={selectedLevel} onChange={setSelectedLevel} compact={compact} />
        </div>

        {(selectedModality === 'in_person') &&
          <div className={`${styles.searchControl} ${controlMarginRight} d-block d-md-flex w-100 mb-md-0 mb-2`}>
            <LocationsHeroSearch location={selectedLocation} onChange={setSelectedLocation} compact={compact} />
          </div>
        }

        <div className={`${styles.searchControl} d-block d-md-flex mb-md-0 mb-2`}>
          <Button onClick={performSearch}>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faMagnifyingGlass} className="me-2" />
              Search
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}
