export default function(theme) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
        primary: '#2c3e50',
        primary25: '#ecf0f1'
    }
  }
}

